@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  overscroll-behavior-y: none;
  font-feature-settings: 'ss04' on, 'salt' on;
}

:root {
  /* #region  /**=========== Primary Color =========== */
  /* !STARTERCONF Customize these variable, copy and paste from /styles/colors.css for list of colors */
  --tw-color-primary-50: 240 249 255;
  --tw-color-primary-100: 224 242 254;
  --tw-color-primary-200: 186 230 253;
  --tw-color-primary-300: 125 211 252;
  --tw-color-primary-400: 56 189 248;
  --tw-color-primary-500: 14 165 233;
  --tw-color-primary-600: 2 132 199;
  --tw-color-primary-700: 3 105 161;
  --tw-color-primary-800: 7 89 133;
  --tw-color-primary-900: 12 74 110;
  --color-primary-50: rgb(var(--tw-color-primary-50)); /* #f0f9ff */
  --color-primary-100: rgb(var(--tw-color-primary-100)); /* #e0f2fe */
  --color-primary-200: rgb(var(--tw-color-primary-200)); /* #bae6fd */
  --color-primary-300: rgb(var(--tw-color-primary-300)); /* #7dd3fc */
  --color-primary-400: rgb(var(--tw-color-primary-400)); /* #38bdf8 */
  --color-primary-500: rgb(var(--tw-color-primary-500)); /* #0ea5e9 */
  --color-primary-600: rgb(var(--tw-color-primary-600)); /* #0284c7 */
  --color-primary-700: rgb(var(--tw-color-primary-700)); /* #0369a1 */
  --color-primary-800: rgb(var(--tw-color-primary-800)); /* #075985 */
  --color-primary-900: rgb(var(--tw-color-primary-900)); /* #0c4a6e */
  /* #endregion  /**======== Primary Color =========== */
}

@layer base {
  .cursor-newtab {
    cursor: url('/images/new-tab.png') 10 10, pointer;
  }

  /* #region  /**=========== Typography =========== */
  .h0 {
    @apply font-primary text-3xl font-bold md:text-5xl;
  }

  h1,
  .h1 {
    @apply font-primary text-2xl font-bold md:text-4xl;
  }

  h2,
  .h2 {
    @apply font-primary text-xl font-bold md:text-3xl;
  }

  h3,
  .h3 {
    @apply font-primary text-lg font-bold md:text-2xl;
  }

  h4,
  .h4 {
    @apply font-primary text-base font-bold md:text-lg;
  }

  body,
  .p {
    @apply font-primary text-sm md:text-base;
  }
  /* #endregion  /**======== Typography =========== */

  .layout {
    /* 1100px */
    /*max-width: 68.75rem;*/
    max-width: 70rem;
    @apply mx-auto w-11/12;
  }
  .max-layout {
    /* 1100px */
    max-width: 100%;
    @apply mx-auto w-11/12;
  }

  .bg-dark a.custom-link {
    @apply border-gray-200 hover:border-gray-200/0;
  }
}

@layer utilities {
  .animated-underline {
    background-image: linear-gradient(#33333300, #33333300),
      linear-gradient(
        to right,
        var(--color-primary-400),
        var(--color-primary-500)
      );
    background-size: 100% 2px, 0 2px;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
  }
  @media (prefers-reduced-motion: no-preference) {
    .animated-underline {
      transition: 0.3s ease;
      transition-property: background-size, color, background-color,
        border-color;
    }
  }
  .animated-underline:hover,
  .animated-underline:focus-visible {
    background-size: 0 2px, 100% 2px;
  }
}

.prose a {
  color: #3f6cf4;
  text-decoration: none;
}

.prose p {
  @apply mt-4;
}
.prose h2 {
  @apply mt-12;
}
.prose h3 {
  @apply mt-12;
}
.prose h4 {
  @apply mt-8 mb-4 block border-b border-gray-200 pb-4 text-[16px] tracking-wide;
}
.prose code {
  @apply rounded-sm bg-slate-200 px-1.5 py-0.5 font-light text-slate-700;
}
.prose code::before {
  content: '';
}
.prose code::after {
  content: '';
}
.prose ul {
  @apply ml-3 mt-3;
}

/* disables box shadow border around input field */
input {
  --tw-ring-shadow: 0 0 #000 !important;
}
